import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Classes, Position, Toaster, Intent, MenuItem, Utils } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { Account, Document, Jurisdiction } from './Types'

import i18next from 'i18next';

interface PageInfo {
    n: number
}

var OpenAccFlag: any;
var auth_no = 0

interface RegMastViewProps {
    OpenAcc: AxiosInstance,
    ac: Account,
    baseUrl?: string,
    token?: string | null,
}

const getDocIcon = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "id-number"
        case "address":
            return "office"
        case "bank":
            return "book"
        case "assets":
            return "book"
        case "sign":
            return "book"
        }
    // contract, others
    return "document"
}

const getDocText = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "ID"
        case "address":
            return "Address"
        case "bank":
            return "Bank Doc"
        case "assets":
            return "Assets Doc"
        case "sign":
            return "Sign"
        case "others":
            return "Others"
    }
    // contract, others
    return "Unknown"
}

function RegMastView({ OpenAcc, ac = {} as Account, baseUrl = "", token = "" }: RegMastViewProps) {

    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)

    const [docList, setDocList] = useState([] as Document[])

    const { t, i18n } = useTranslation();    

    const [def_d_ClientType, setdef_d_ClientType] = useState(ac.d_ClientType)

    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")
    const [dateFormat, setdateFormat] = useState("1")
    const [def_FuncPrview, setdef_FuncPrview] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag');
                //console.log("RegMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("RegMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("RegMastMain disp data.Flag: ", res.data.Flag)
                OpenAccFlag = res.data.Flag;
                //console.log("RegMastMain disp OpenAccFlag: ", OpenAccFlag)
                //console.log("RegMastMain disp OpenAccFlag.lang: ", OpenAccFlag.lang)
                //console.log("RegMastMain disp OpenAccFlag.dateFormat: ", OpenAccFlag.dateFormat)
                //console.log("OpenAccFlag.user_key: ", OpenAccFlag.user_key)
                //console.log("OpenAccFlag.UserID: ", OpenAccFlag.UserID)
                //console.log("RegMastMain disp OpenAccFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("RegMastMain disp OpenAccFlag.MGNGP_USER: ", OpenAccFlag.MGNGP_USER)
                //console.log("RegMastMain disp OpenAccFlag.CO_NAME: ", OpenAccFlag.CO_NAME)
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                setdateFormat(OpenAccFlag.dateFormat)

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                //showError(error)
            }
        })()
    }, [OpenAcc])

    useEffect(() => {
        (async () => {
            try {
                const res = await OpenAcc.post('/documentlist',
                    {
                        idNo: ac.d_idNo ,
                        UploadId: ac.d_UploadId
                    });
                setDocList(res.data.data)
                //console.log("RegMastView DocList count: ",res.data.count) 
                //console.log("RegMastView DocList: ",res.data.data) 
                
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [OpenAcc, ac.d_idNo, ac.d_UploadId, ac.d_ClientType])




    if (! ("d_acCode" in ac)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>


    let dispRegForm =
    <div>
        <div className="Spacing-V-16" />
        <div className="Row">
            <h4 className="bp3-heading DispText2">{t('Preview01')}</h4>
            { ! def_FuncPrview && <Button className="DispText8 No-Print" type="submit" onClick={ () => window.print() }>{t('Print')}</Button> }
        </div>
        { def_FuncPrview && <div className="Row">
            <div className="DispText2">{t('AppDate')} : {TRAN_DATE}</div>
        </div> }
        { def_FuncPrview && <div className="Spacing-V-16"></div> }
        { ! def_FuncPrview && <div className="Row">
            <FormGroup className="Col-1-2" label={t('Account_No')} >
                <Tooltip content={AcNOToolTip} position={Position.TOP}>
                    <InputGroup readOnly value={ac.d_acCode} />
                </Tooltip>
            </FormGroup>
            <FormGroup className="Col-3-3" label={t('Name')}>
                <InputGroup readOnly value={ac.d_acName1} />
                <InputGroup readOnly value={ac.d_acName2} />
            </FormGroup>
            <FormGroup className="Col-6-3" label={t('HKIdNo')}>
                <InputGroup readOnly value={ac.d_idNo} />
            </FormGroup>
            <FormGroup className="Col3-9-1" label={t('Sex')} >
                <InputGroup readOnly value={ac.d_Sex} />
            </FormGroup>
        </div> }
        { def_FuncPrview && <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-1-2" label={t('NameEng')} >
                <InputGroup readOnly value={ac.d_acName1} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-3" label={t('NameChi')}>
                <InputGroup readOnly value={ac.d_acName2} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-6-3" label={t('HKIdNo')}>
                <InputGroup readOnly value={ac.d_idNo} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-9-1" label={t('Sex')} >
                <InputGroup readOnly value={ac.d_Sex} />
            </FormGroup>
        </div> }
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('ClientType')} labelFor="InputClientType">
                <Switch readOnly label="" innerLabelChecked={t('ClientType01')} innerLabel={t('ClientType02')} checked={(ac.d_ClientType==="C")} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-2" label={t('DateofBirth')} labelFor="inputDoB">
                <InputGroup readOnly value= {utils.dispDate(ac.d_dob, dateFormat)} />
            </FormGroup>
            <FormGroup className="Col-3-2" label={t('Nationality')} labelFor="inputIdNo" >
                <InputGroup readOnly value={ac.d_National} />
            </FormGroup>
        </div>
        { ! def_FuncPrview && <div className="Row">
            <FormGroup className="Col-1-2" label="Input DateTime">  
                <InputGroup readOnly value= {utils.dispDate(ac.d_inpputDate, OpenAccFlag.dateFormat)} />
                <InputGroup readOnly value= {ac.d_inpputTime} />
            </FormGroup>
            <FormGroup className="Col-3-2" label="Verify Email DateTime">
                <InputGroup readOnly value= {utils.dispDate(ac.d_verify_Date, OpenAccFlag.dateFormat)} />
                <InputGroup readOnly value= {ac.d_verify_Time} />
            </FormGroup>
            <FormGroup className="Col-5-2" label="Authority DateTime/User">
                <InputGroup readOnly value= {utils.dispDate(ac.d_Authority_date, OpenAccFlag.dateFormat)} />
                <InputGroup readOnly value= {ac.d_Authority_time} />
                <InputGroup readOnly value= {ac.d_Authority_user} />
            </FormGroup>
            <FormGroup className="Col-7-3" label="Generate A/C DateTime/User">
                <InputGroup readOnly value= {utils.dispDate(ac.d_Generate_AC_date, OpenAccFlag.dateFormat)} />
                <InputGroup readOnly value= {ac.d_Generate_AC_time} />
                <InputGroup readOnly value= {ac.d_Generate_AC_user} />
            </FormGroup>
            <FormGroup className="Col-10-3" label="Export CSV DateTime/User">
                <InputGroup readOnly value= {utils.dispDate(ac.d_Export_AC_date, OpenAccFlag.dateFormat)} />
                <InputGroup readOnly value= {ac.d_Export_AC_time} />
                <InputGroup readOnly value= {ac.d_Export_AC_user} />
            </FormGroup>
        </div> }
        <div className="Row">
            <FormGroup className="Col-1-4" label={t('ResidentialAddress')}>
                <InputGroup readOnly value={ac.d_acAddr1} />
                <InputGroup readOnly value={ac.d_acAddr2} />
                <InputGroup readOnly value={ac.d_acAddr3} />
            </FormGroup>
            <FormGroup className="Col-5-4" label={t('CorrespondenceAddress2')}>
                <InputGroup readOnly value={ac.d_mailAddr1} />
                <InputGroup readOnly value={ac.d_mailAddr2} />
                <InputGroup readOnly value={ac.d_mailAddr3} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-4" label={t('EmailAddress')}>
                <InputGroup readOnly value={ac.d_email} />
            </FormGroup>
            { ! def_FuncPrview && <FormGroup className="Col-5-2" label={t('Send_e-mail_Statement')} labelFor="inputSendemail">
                <Switch readOnly id="inputSendemail" label="" innerLabelChecked="Send" innerLabel="not Send" checked={(ac.d_sendemail==="Y")} />
            </FormGroup> }
        </div>
        <div className="Row">
            <FormGroup className="Col3-1-5" label={t('way2GetStmt')} labelFor="inputway2GetStmt">
                <InputGroup readOnly value={ac.d_way2GetStmt} />
                { ac.d_way2GetStmt === "1" && <span >{t('way2GetStmt01')} </span> }
                { ac.d_way2GetStmt === "2" && <span >{t('way2GetStmt02')} </span> }
                { ac.d_way2GetStmt === "3" && <span >{t('way2GetStmt03')} </span> }
            </FormGroup>
        </div>
        {t('way2GetStmt2')}
        <div className="Spacing-V-16"></div>
        <div className="Row">
            <FormGroup className="Col-1-2" label={t('TelNoRes')} labelFor="inputTel">
                <InputGroup readOnly value={ac.d_tel} />
            </FormGroup>
            <FormGroup className="Col-3-2" label={t('TelNoOff')} labelFor="inputoffTel">
                <InputGroup readOnly value={ac.d_offTel} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col3-1-2" label={t('MobilePhoneCode')} labelFor="inputMobileArea" labelInfo="*">
                <InputGroup readOnly value={ac.d_mobileCode} />
            </FormGroup>
            <FormGroup className="Col-3-2" label={t('MobilePhone')} labelFor="inputMobile" labelInfo="*">
                <InputGroup readOnly value={ac.d_mobile} />
            </FormGroup>
            <FormGroup className="Col-5-2" label={t('Fax')} labelFor="inputFax">
                <InputGroup readOnly value={ac.d_fax} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>

        <div className="Row">
            <FormGroup className="Col3-1-2" label={t('Employment1')} labelFor="inputEmployment">
                <InputGroup readOnly value={ac.d_Employment} />
                { ac.d_Employment === "1" && <span >{t('Employment01')} </span> }
                { ac.d_Employment === "2" && <span >{t('Employment02')} </span> }
                { ac.d_Employment === "3" && <span >{t('Employment03')} </span> }
                { ac.d_Employment === "4" && <span >{t('Employment04')} </span> }
                { ac.d_Employment === "5" && <span >{t('Employment05')} </span> }
                { ac.d_Employment === "6" && <span >{t('Employment06')} </span> }
            </FormGroup>
            <FormGroup className="Col-3-4" label={t('Employment2')} labelFor="inputEmploymentOther">
                <InputGroup readOnly value={ac.d_EmploymentOther} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('Empl_CoName')} labelFor="inputCoName">
                <InputGroup readOnly value={ac.d_CoName} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('Empl_FinInstruction')} labelFor="InputselfInstruction">
                {/* <Switch readOnly id="InputisFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_isFinInstit==="Y")} /> */}
                <RadioGroup
                    name="def_d_isFinInstit"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_isFinInstit}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('Empl_approveFinInstruction')} labelFor="InputselfInstruction">
                {/* <Switch readOnly id="InputApproveFinInstit" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')}  checked={(ac.d_ApproveFinInstit==="Y")} /> */}
                <RadioGroup
                    name="def_d_ApproveFinInstit"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_ApproveFinInstit}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('Empl_CoAddr')} labelFor="inputCoAddr">
                <InputGroup readOnly value={ac.d_CoAddr} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-3" label={t('Empl_CoType')} labelFor="inputCoType">
                <InputGroup readOnly value={ac.d_CoType} />
            </FormGroup>
            <FormGroup className="Col-4-3" label={t('Empl_CoPost')} labelFor="inputCoPost">
                <InputGroup readOnly value={ac.d_CoPost} />
            </FormGroup>
        </div>
        { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        <div className="Row">
            <FormGroup className="Col3-1-2" label={t('NetAsset')} labelFor="inputNetAsset">
                <InputGroup readOnly value={ac.d_NetAsset} />
                { ac.d_NetAsset === "1" && <span >{t('NetAsset01')} </span> }
                { ac.d_NetAsset === "2" && <span >{t('NetAsset02')} </span> }
                { ac.d_NetAsset === "3" && <span >{t('NetAsset03')} </span> }
                { ac.d_NetAsset === "4" && <span >{t('NetAsset04')} </span> }
                { ac.d_NetAsset === "5" && <span >{t('NetAsset05')} </span> }
                { ac.d_NetAsset === "6" && <span >{t('NetAsset06')} </span> }
                { ac.d_NetAsset === "7" && <span >{t('NetAsset07')} </span> }
                { ac.d_NetAsset === "8" && <span >{t('NetAsset08')} </span> }
            </FormGroup>
            <FormGroup className="Col3-3-2" label={t('AnnualIncome')} labelFor="inputAnnualIncome">
                <InputGroup readOnly value={ac.d_AnnualIncome} />
                { ac.d_AnnualIncome === "1" && <span >{t('AnnualIncome01')} </span> }
                { ac.d_AnnualIncome === "2" && <span >{t('AnnualIncome02')} </span> }
                { ac.d_AnnualIncome === "3" && <span >{t('AnnualIncome03')} </span> }
                { ac.d_AnnualIncome === "4" && <span >{t('AnnualIncome04')} </span> }
                { ac.d_AnnualIncome === "5" && <span >{t('AnnualIncome05')} </span> }
                { ac.d_AnnualIncome === "6" && <span >{t('AnnualIncome06')} </span> }
                { ac.d_AnnualIncome === "7" && <span >{t('AnnualIncome07')} </span> }
                { ac.d_AnnualIncome === "8" && <span >{t('AnnualIncome08')} </span> }
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-3" label={t('SourceofFund')} labelFor="inputSourceofFund">
                <InputGroup readOnly value={ac.d_SourceofFund} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <div className="Row">
            <FormGroup className="Col-1-2" label={t('InvestTarget1')} labelFor="inputTarget">
                <Checkbox name="d_Target1" label={t('InvestTarget01')} readOnly checked={(ac.d_Target1==="Y")}  />
                <Checkbox name="d_Target2" label={t('InvestTarget02')} readOnly checked={(ac.d_Target2==="Y")}  />
                <Checkbox name="d_Target3" label={t('InvestTarget03')} readOnly checked={(ac.d_Target3==="Y")}  />
            </FormGroup>
            <FormGroup className="Col-3-3" label={t('InvestTarget2')} labelFor="inputTarget">
                <Checkbox name="d_Target4" label={t('InvestTarget04')} readOnly checked={(ac.d_Target4==="Y")}  />
                <Checkbox name="d_Target5" label={t('InvestTarget05')} readOnly checked={(ac.d_Target5==="Y")}  />
                <Checkbox name="d_Target6" label={t('InvestTarget06')} readOnly checked={(ac.d_Target6==="Y")}  />
            </FormGroup>
            <FormGroup className="Col-6-3" label=" ." labelFor="inputTarget">
                <Checkbox name="d_Target7" label={t('InvestTarget07')} readOnly checked={(ac.d_Target7==="Y")}  />
                <Checkbox name="d_Target8" label={t('InvestTarget08')} readOnly checked={(ac.d_Target8==="Y")}  />
                <Checkbox name="d_Target9" label={t('InvestTarget09')} readOnly checked={(ac.d_Target9==="Y")}  />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <h5 className="bp3-heading">{t('InvestExperience')}</h5>
        <div className="Row">
            <FormGroup className="Col-1-3" label={t('InvestExperience01')} labelFor="inputStockYear">
                <InputGroup readOnly value={ac.d_StockYear} />
                <InputGroup readOnly value={ac.d_StockCnt} />
                <InputGroup readOnly value={ac.d_StockAmt} />
            </FormGroup>
            <FormGroup className="Col-4-3" label="." labelFor="">
                <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <h5 className="bp3-heading">{t('DerivativeExperience')}</h5>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('DerivativeExperience01')} labelFor="InputHighRiskExp">
                {/* <Switch readOnly id="InputHighRiskExp" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_HighRiskExp==="Y")} /> */}
                <RadioGroup
                    name="def_d_HighRiskExp"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_HighRiskExp}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-3" label={t('InvestExperience02')} labelFor="inputWarrentYear">
                <InputGroup readOnly value={ac.d_WarrantYear} />
                <InputGroup readOnly value={ac.d_WarrantCnt} />
                <InputGroup readOnly value={ac.d_WarrantAmt} />
            </FormGroup>
            <FormGroup className="Col-4-3" label={t('InvestExperience03')} labelFor="inputOptionYear">
                <InputGroup readOnly value={ac.d_OptionYear} />
                <InputGroup readOnly value={ac.d_OptionCnt} />
                <InputGroup readOnly value={ac.d_OptionAmt} />
            </FormGroup>
            <FormGroup className="Col-7-3" label={t('InvestExperience04')} labelFor="inputFutureYear">
                <InputGroup readOnly value={ac.d_FutureYear} />
                <InputGroup readOnly value={ac.d_FutureCnt} />
                <InputGroup readOnly value={ac.d_FutureAmt} />
            </FormGroup>
            <FormGroup className="Col-10-3" label="." labelFor="">
                <InputGroup readOnly disabled={true} value={t('InvestExperience05')} />
                <InputGroup readOnly disabled={true} value={t('InvestExperience06')} />
                <InputGroup readOnly disabled={true} value={t('InvestExperience07')} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        { (userKey === "ASTRUM")  && <div className="Row">
            <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation01')} labelFor="InputStaffRelation">
            {/* <Switch readOnly id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={(ac.d_StaffRelation==="Y")} /> */}
            <RadioGroup
                    name="def_d_StaffRelation"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_StaffRelation}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes01')} value="Y" />
                    <Radio label={t('cNo01')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div> }
        { ! (userKey === "ASTRUM")  && <div className="Row">
            <FormGroup className="Col-1-5" label={t('OpenDecld_StaffRelation02')} labelFor="InputStaffRelation">
                {/* <Switch readOnly id="InputStaffRelation" label="" innerLabelChecked={t('cYes01')} innerLabel={t('cNo01')} checked={(ac.d_StaffRelation==="Y")} /> */}
                <RadioGroup
                    name="def_d_StaffRelation"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_StaffRelation}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes01')} value="Y" />
                    <Radio label={t('cNo01')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div> }
        <div className="Row">
            <FormGroup className="Col-1-3" label={t('OpenDecld_StaffRelation03')} labelFor="inputStaffName">
                <InputGroup readOnly value={ac.d_StaffName} />
            </FormGroup>
            <FormGroup className="Col-4-2" label={t('OpenDecld_StaffRelation04')} labelFor="inputStaffRelationship">
                <InputGroup readOnly value={ac.d_StaffRelationship} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('OpenDecld_selfInstruction01')} labelFor="InputselfInstruction">
                {/* <Switch readOnly id="InputselfInstruction" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_selfInstruction==="Y")} /> */}
                <RadioGroup
                    name="def_d_selfInstruction"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_selfInstruction}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-4" label={t('OpenDecld_selfInstruction02')} labelFor="inputInstructionType">
                <InputGroup readOnly value={ac.d_InstructionType} />
            </FormGroup>
        </div>
        { (def_d_ClientType == "M") && <div className="Spacing-V-16"></div> }
        { (def_d_ClientType == "M") && <h5 className="bp3-heading">{t('OpenRelatedPersion')}</h5> }
        { (def_d_ClientType == "M") && <div className="Row">
            <FormGroup className="Col-1-5" label={t('OpenhasSpouseClient01')} labelFor="InputhasSpouseClient">
                {/* <Switch readOnly id="InputhasSpouseClient" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')}  checked={(ac.d_hasSpouseClient==="Y")} /> */}
                <RadioGroup
                    name="def_d_hasSpouseClient"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_hasSpouseClient}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div> }
        { (def_d_ClientType == "M") && <div className="Row">
            <span id="DispText2">{t('OpenhasSpouseClient02')}</span>
        </div> }
        { (def_d_ClientType == "M") && <div className="Row">
            <FormGroup className="Col-1-4" label={t('OpenhasSpouseClient02a')} labelFor="inputSpouseName">
                <InputGroup readOnly value={ac.d_SpouseName} />
            </FormGroup>
            <FormGroup className="Col-6-1" label={t('OpenhasSpouseClient03')} labelFor="inputSpouseAcNo">
                <InputGroup readOnly value={ac.d_SpouseAcNo} />
            </FormGroup>
        </div> }
        { (def_d_ClientType == "M") && <div className="Row">
            <FormGroup className="Col-1-7" label={t('OpenVoteRight01')} labelFor="InputVoteRight">
                {/* <Switch readOnly id="InputVoteRight" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_VoteRight==="Y")} /> */}
                <RadioGroup
                    name="def_d_VoteRight"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_VoteRight}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div> }
        { (def_d_ClientType == "M") && <div className="Row">
            <span id="DispText2">{t('OpenVoteRight02')}</span>
        </div> }
        { (def_d_ClientType == "M") && <div className="Row">
            <FormGroup className="Col-1-3" label={t('OpenVoteRight02a')}  labelFor="inputVoteAc">
                <InputGroup readOnly value={ac.d_VoteAc1} />
                <InputGroup readOnly value={ac.d_VoteAc2} />
            </FormGroup>
            <FormGroup className="Col-4-4" label={t('OpenVoteRight03')}  labelFor="inputIoteAcName1">
                <InputGroup readOnly value={ac.d_VoteAcName1} />
                <InputGroup readOnly value={ac.d_VoteAcName2} />
            </FormGroup>
            <FormGroup className="Col-8-3" label={t('OpenVoteRight04')}  labelFor="inputSpouseAcNo">
                <InputGroup readOnly value={ac.d_VoteRelate1} />
                <InputGroup readOnly value={ac.d_VoteRelate2} />
            </FormGroup>
        </div> }
        { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        <h5 className="bp3-heading">{t('isInternet01')}</h5>
        <div className="Row">
            <FormGroup className="Col-1-5" label={t('isInternet02')} labelFor="InputisInternet">
                {/* <Switch readOnly id="InputisInternet" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_isInternet==="Y")} /> */}
                <RadioGroup
                    name="def_d_isInternet"
                    disabled={true}
                    inline={true}
                    selectedValue={ac.d_isInternet}
                    onChange={(s) => { }}
                >
                    <Radio label={t('cYes')} value="Y" />
                    <Radio label={t('cNo')}  value="N" />
                </RadioGroup>
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>

        <h5 className="bp3-heading">CRS</h5>
        <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                <InputGroup readOnly value={ac.d_JurisdictionofResidence} />
            </FormGroup>
            {/* <FormGroup className="Col-3-3" label="其它 司法管轄區" labelFor="inputd_OtherJurisdictionofResidence">
                <InputGroup readOnly value={ac.d_OtherJurisdictionofResidence} />
            </FormGroup> */}
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                <InputGroup readOnly value={ac.d_TIN} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                <InputGroup readOnly value={ac.d_Juris_ReasonA} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                <InputGroup readOnly value={ac.d_Juris_Reason} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                <InputGroup readOnly value={ac.d_JurisdictionofResidence2} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                <InputGroup readOnly value={ac.d_TIN2} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')} labelFor="inputd_Juris_ReasonA" >
                <InputGroup readOnly value={ac.d_Juris_Reason2A} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                <InputGroup readOnly value={ac.d_Juris_Reason2} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                <InputGroup readOnly value={ac.d_JurisdictionofResidence3} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                <InputGroup readOnly value={ac.d_TIN3} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                <InputGroup readOnly value={ac.d_Juris_Reason3A} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                <InputGroup readOnly value={ac.d_Juris_Reason3} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                <InputGroup readOnly value={ac.d_JurisdictionofResidence4} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                <InputGroup readOnly value={ac.d_TIN4} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                <InputGroup readOnly value={ac.d_Juris_Reason4A} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                <InputGroup readOnly value={ac.d_Juris_Reason4} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-2" label={t('JurisdictionofResidence01')} labelFor="inputd_JurisdictionofResidence">
                <InputGroup readOnly value={ac.d_JurisdictionofResidence5} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-3-2" label={t('JurisdictionofResidence03')} labelFor="inputd_TIN">
                <InputGroup readOnly value={ac.d_TIN5} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-5-3" label={t('JurisdictionofResidence04')}  labelFor="inputd_Juris_ReasonA" >
                <InputGroup readOnly value={ac.d_Juris_Reason5A} />
            </FormGroup>
            <FormGroup style={{justifyContent: 'flex-end'}} className="Col-8-5" label={t('JurisdictionofResidence05')}  labelFor="inputd_Juris_Reason" >
                <InputGroup readOnly value={ac.d_Juris_Reason5} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <h5 className="bp3-heading">W-8BEN</h5>
        <ol>
            <li>
                <div className="Row vertical_alignment">
                    <span id="DispText4">
                        {t('OpenW8B01')}
                    </span>
                    <span id="DispText5">
                        <InputGroup readOnly value={ac.d_w8b_country} />
                    </span>
                    <span id="DispText6">
                        {t('OpenW8B01a')}
                    </span>
                </div>
                <div className="Row vertical_alignment">
                    <span id="DispText6">
                        {t('OpenW8B01b')}
                    </span>
                </div>
            </li>
            <div className="Spacing-V-16"></div>
            <li>
                <div className="Row vertical_alignment">
                    <span id="DispText6">
                        {t('OpenW8B02b')}
                    </span>
                </div>
                <div className="Row vertical_alignment">
                    <span id="DispText9">
                        {t('OpenW8B02b1')}
                    </span>
                    <span id="DispText11">
                        <InputGroup readOnly value={ac.d_w8b_SR01} />
                    </span>
                    <span id="DispText10">
                        {t('OpenW8B02c')}
                    </span>
                    <span id="DispText11">
                        <InputGroup readOnly value={ac.d_w8b_SR02} />
                    </span>
                </div>
                <div className="Row vertical_alignment">
                    <span id="DispText4">
                        {t('OpenW8B02d')}
                    </span>
                    <span id="DispText7">
                        <InputGroup readOnly value={ac.d_w8b_SR03} />
                    </span>
                </div>
                <div className="Row vertical_alignment">
                    <span id="DispText6">
                        {t('OpenW8B02e')}
                    </span>
                </div>
                <div className="Row vertical_alignment">
                    <span id="DispText7">
                        <InputGroup readOnly value={ac.d_w8b_SR04} />
                    </span>
                </div>
            </li>
        </ol>
        <div className="Spacing-V-16"></div>
        <div className="Row">
            <span id="DispText6">
                &nbsp; &nbsp; &nbsp; {t('OpenW8B02a')}
            </span>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_01" labelInfo="*">
                <Checkbox name="d_w8b_01"  label={t('OpenW8B03')} readOnly checked={(ac.d_w8b_01==="Y")}  />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                <Checkbox name="d_w8b_02"  label={t('OpenW8B04')} readOnly checked={(ac.d_w8b_02==="Y")}  />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                <Checkbox name="d_w8b_03"  label={t('OpenW8B05')} readOnly checked={(ac.d_w8b_03==="Y")}  />
            </FormGroup>
        </div>
        <ul className="bp3-list">
            <li>{t('OpenW8B05a')}</li>
            <li>{t('OpenW8B05b')}</li>
            <li>{t('OpenW8B05c')}</li>
            <li>{t('OpenW8B05d')}</li>
        </ul>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                <Checkbox name="d_w8b_04"  label={t('OpenW8B06')} readOnly checked={(ac.d_w8b_04==="Y")}  />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                <Checkbox name="d_w8b_05"  label={t('OpenW8B07')} readOnly checked={(ac.d_w8b_05==="Y")}  />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-10" label="" labelFor="inputd_w8b_02" labelInfo="*">
                <Checkbox name="d_w8b_06"  label={t('OpenW8B08')} readOnly checked={(ac.d_w8b_06==="Y")}  />
            </FormGroup>
        </div>
        { ! def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        { def_FuncPrview && <div className="Spacing-V-16 Page-Break-After"></div> }
        <div className="Row">
            <FormGroup className="Col-1-2" label={t('OpenBankInfo01')} labelFor="inputBankInfo" labelInfo="*">
                <InputGroup readOnly value={ac.d_BankInfo} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-2" label={t('OpenBankInfo02')} labelFor="inputBankACNo" labelInfo="*">
                <InputGroup readOnly value={ac.d_BankACNo} />
            </FormGroup>
        </div>
        <div className="Spacing-V-16"></div>
        <ul>
            <li>{t('BankNote01')}</li>
            <li>{t('BankNote02')}</li>
            {/* <li>{t('BankNote03')}</li> */}
        </ul>

        <div className="Spacing-V-16"></div>
        <div className="Spacing-V-16"></div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree01')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree==="Y")} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree02')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree2==="Y")} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree03')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree3==="Y")} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree04')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree4==="Y")} />
            </FormGroup>
        </div>
        <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree05')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree5==="Y")} />
            </FormGroup>
        </div>
        { (def_d_ClientType == "M") && <div className="Row">
            <FormGroup className="Col-1-7" label={t('Agree06')} labelFor="InputAgree">
                <Switch disabled={true} id="InputAgree" label="" innerLabelChecked={t('cYes')} innerLabel={t('cNo')} checked={(ac.d_Agree6==="Y")} />
            </FormGroup>
        </div> }
        <div className="Spacing-V-16"></div>
    </div>

    return <>

            {dispRegForm}

            <div className="Spacing-V-16"></div>
            <div className="Row">
                <FormGroup className="Col-1-6" label={t('Remark')}>
                    <TextArea readOnly fill rows={4} value={ac.d_Remark} />
                </FormGroup>
            </div>
            
            <div className="Spacing-V-16"></div>
            {t('Sign04')}
            <div className="Spacing-V-16"></div>
            {/* <img className="signimg" src={`${baseUrl}/documentdownload?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} /> */}
            <img className="signimg" src={`${baseUrl}/documentdownload2?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} />
            {/* <img className="signimg" src={`${OpenAcc.defaults.baseURL}/documentdownload2?doctype=sign&filename=${ac.d_UploadId}_sign.png&inline`} /> */}
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <p className="No-Print">{t('Documents')}</p>
            <div className="Row No-Print">
                {docList.map((doc, i) =>
                    /* <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload?doctype=${doc.docType}&filename=${doc.fileName}&inline`}>{getDocText(doc.docType)}</AnchorButton> */
                    <AnchorButton key={i} icon={getDocIcon(doc.docType)} target="_blank" href={`${baseUrl}/documentdownload2?doctype=${doc.docType}&filename=${doc.fileName}&inline`}>{getDocText(doc.docType)}</AnchorButton> 
                )}
            </div>

        </>

}




interface RegMastViewMainProps {
    OpenAcc: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function RegMastViewMain({ OpenAcc }: RegMastViewMainProps) {
    
    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let acCode = ""
    let acCode = utils.getUrlParameter('acCode', url); 
    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()
 

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const cologo = <img className="cologo" src="cologo.gif" alt="Co Logo" />
    const astrum_cologo = <img className="cologo" src="astrum_cologo.gif" alt="Co Logo" />
    const demo_cologo = <img className="cologo" src="pop_cologo.gif" alt="Co Logo" />


    const [data, setData] = useState([] as Account[])

    const [aeList, setAeList] = useState([] as Account[])
    const [JurisdictionList, setJurisdictionList] = useState([] as Jurisdiction[])

    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [RptName, setRptName] = useState("")
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)


    const [CO_NAME, setCO_NAME] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")

    //OpenAcc default value

    console.log("main url: ", url)
    console.log("main qString: ", qString)
    console.log("main acCode: ", acCode)


    useEffect(() => {
        (async () => {
            try {
                // get OpenAccFlag from SB.XBS
                const res = await OpenAcc.post('/OpenAccFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                OpenAccFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( OpenAccFlag.CO_NAME) ;
                setTRAN_DATE(OpenAccFlag.TRAN_DATE ) ;
                setuserKey(OpenAccFlag.user_key) ;
                //i18next.changeLanguage("en")

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])



/*
    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await OpenAcc.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: "C",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                                aeList: "Y",
                            }
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListRec",
                    });
                setAeList(res.data.data)
                //setAeList(utils.sortByColumn(res.data.data, "d_acName1"))


            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])
*/


    useEffect(() => {

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "ListJurisdiction",
                    });
                setJurisdictionList(res.data.data)
                console.log("ListJurisdiction res.data.data", res.data.data);
                console.log("ListJurisdiction JurisdictionList", JurisdictionList);
 
            } catch (error) {
                showError(error)
            }
        })()
    }, [OpenAcc])


    useEffect(() => {
        if ( acCode.trim().length > 0 ) {

            setShowNoRecord(false)
            setShowRpt(false)
 

            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                try {
                    //console.log("SmMastMain onsubmit called", data)
                    const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            acCode: acCode,
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "GetRec",
                    });

                    console.log("acCode res.data.data: ", res.data.data, res.data.count) 
                    if ( res.data.count > 0 ) {
                        setCurrentAc(res.data.data)

                        setShowNoRecord(false)
                        setShowRpt(true)
              
                    } else {
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    showError(error)
                }
            })()
        } else if ( qString.trim().length > 8 ) {

            setShowNoRecord(false)
            setShowRpt(false)
 

            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                try {
                    //console.log("SmMastMain onsubmit called", data)
                    const res = await OpenAcc.post('/Master0',
                    {   sb_data: {
                            acCode: qString,
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "OpenAcc",
                        cAction: "GetRec2",
                    });

                    console.log("acCode 2 res.data.data: ", res.data.data, res.data.count) 
                    if ( res.data.count > 0 ) {
                        setCurrentAc(res.data.data)

                        setShowNoRecord(false)
                        setShowRpt(true)
              
                    } else {
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    showError(error)
                }
            })()
        }

    }, [OpenAcc])



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }


    const showErrorBox = (err: any) => {
        
        alert(err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <RegMastView OpenAcc={OpenAcc} ac={currentAc} baseUrl={OpenAcc.defaults.baseURL} token={localStorage.getItem('token')}  ></RegMastView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    

    let CoHead = <>
        { userKey == "DEMO" && <span id="DispCoName"> {demo_cologo} <b> { CO_NAME } </b> </span> }
        { userKey == "ASTRUM" && <span id="DispCoName"> {astrum_cologo} <b> { CO_NAME } </b> </span> }
        <span id="DispTrnDate">Sys Date: {TRAN_DATE} </span>
    </>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>

                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record found </span>
                        </FormGroup>
                    </div>
                </p>
                }

                { ShowRpt && detail }

            </form>
        </>
    );
}

export default RegMastViewMain